import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDocuments(ctx, { prst_uuid }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/digital_file/digital_file_record/record/${prst_uuid}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    labTest(ctx, { prst_uuid, elementData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/cases/labtest/first_date/${prst_uuid}`, elementData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_key_value(ctx, { type }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/core/type_value/${type}`, {
            params: { deep: 1 },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addFile(ctx, {
      prst_uuid, folder_key, attribute_to_update, elementData,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/digital_file/digital_file_record/digital_file/${prst_uuid}/${folder_key}/${attribute_to_update}`, elementData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editTemplate(ctx, { id, templateData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/core/template/${id}`, templateData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_case_date_status(ctx, { step_uuid }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/process/case_date/status/${step_uuid}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
