<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="templateData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching template data
      </h4>
    </b-alert>

    <b-card>
      <b-card-header>
        <div class="text-primary h2">
          <feather-icon
            icon="BookIcon"
            size="25"
            class="mr-0 mr-sm-50"
            variant="primary"
          />
          <span class="d-none d-sm-inline">Adjuntar documentos</span>
        </div>
      </b-card-header>
      <b-card-body v-if="loaded">
        <template-edit
          v-if="templateData && uploaded_files === false"
          :template-data="templateData"
          :new-template="newTemplate"
          class="mt-2 pt-75"
        />
        <b-alert
          v-else
          variant="warning"
          show
          class="p-2 font-weight-bolder"
        >
          {{ uploaded_files_message }}
        </b-alert>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BAlert,
} from 'bootstrap-vue'
import router from '@/router'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TemplateStoreModule from './storeModule'
import TemplateEdit from './EditDocuments.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BAlert,
    TemplateEdit,
  },
  data() {
    return {
      newTemplate: true,
      templateData: { source_exams: { file: [] }, patient_picture: { file: [] }, initial_exams: { file: [] } },
      TEMPLATE_APP_STORE_MODULE_NAME: 'app-first-document',
      case_uuid: undefined,
      prst_uuid: undefined,
      labtest_types: [],
      uploaded_files: false,
      uploaded_files_message: '',
      case_date_status: {},
      loaded: false,
    }
  },
  async mounted() {
    this.case_uuid = router.currentRoute.params.case_uuid
    this.prst_uuid = router.currentRoute.params.prst_uuid
    // Register module
    if (!store.hasModule(this.TEMPLATE_APP_STORE_MODULE_NAME)) store.registerModule(this.TEMPLATE_APP_STORE_MODULE_NAME, TemplateStoreModule)

    await this.fetchDocuments()

    this.labtest_types = await this.fetchKeyValue('destination_order')

    const processMessages = await this.fetchKeyValue('process_messages')
    this.uploaded_files_message = processMessages.find(element => element.key === 'BL_INIT_EXAMS').value

    this.case_date_status = await this.fetchCaseDateStatus(this.prst_uuid)
    if (!this.uploaded_files) {
      this.uploaded_files = this.case_date_status.key === 'ATTENDED'
    }
    this.loaded = true
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.TEMPLATE_APP_STORE_MODULE_NAME)) store.unregisterModule(this.TEMPLATE_APP_STORE_MODULE_NAME)
  },
  methods: {
    async fetchKeyValue(type) {
      return store.dispatch(`${this.TEMPLATE_APP_STORE_MODULE_NAME}/fetch_key_value`, { type })
        .then(response => {
          const list = response.data.items
          list.unshift({ id: null, value: this.$t('list.defaultOption') })
          return list
        })
        .catch(error => {
          if (error.response.status === 404) {
            return []
          }
        })
    },
    async fetchCaseDateStatus(step_uuid) {
      return store.dispatch(`${this.TEMPLATE_APP_STORE_MODULE_NAME}/fetch_case_date_status`, { step_uuid }).then(async response => response.data).catch(error => {
        if (error.response.status === 404) {
          return undefined
        }
      })
    },
    fetchDocuments() {
      store.dispatch(`${this.TEMPLATE_APP_STORE_MODULE_NAME}/fetchDocuments`, { prst_uuid: this.prst_uuid })
        .then(response => {
          this.templateData = {
            source_exams: response.data.find(element => element.documental_type.key === 'SOURCE_EXAMS') || { file: [] },
            patient_picture: response.data.find(element => element.documental_type.key === 'PATIENT_PICTURE') || { file: [] },
            initial_exams: response.data.find(element => element.documental_type.key === 'INITIAL_EXAMS') || { file: [] },
          }
          for (const element in this.templateData) {
            if (this.templateData[element].path) {
              this.uploaded_files = true
              break
            }
          }
          this.templateData.source_exams.file = []
          this.templateData.patient_picture.file = []
          this.templateData.initial_exams.file = []
        })
        .catch(error => {
          this.templateData = { source_exams: { file: [] }, patient_picture: { file: [] }, initial_exams: { file: [] } }
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error consultando los documentos actuales',
              icon: 'ErrorIcon',
              variant: 'danger',
              text: 'No se han encontrado documentos cargados en el sistema',
            },
          })
        })
    },
    async create_or_update_lab_test(elementData) {
      return store.dispatch(`${this.TEMPLATE_APP_STORE_MODULE_NAME}/labTest`, { elementData, prst_uuid: this.prst_uuid })
        .then(response => {
          console.log(response)
          return true
        }).catch(error => {
          console.error(error)
          return false
        })
    },
    async save(elementData, folder_key, attribute_to_update) {
      const filename = elementData.get('file').name
      store.dispatch(`${this.TEMPLATE_APP_STORE_MODULE_NAME}/addFile`, {
        elementData, prst_uuid: this.prst_uuid, folder_key, attribute_to_update,
      })
        .then(async response => {
          if (attribute_to_update !== 'patient_picture') {
            let type
            if (attribute_to_update === 'source_exams') {
              type = this.labtest_types.find(element => element.key == 'SOURCE')
            } else {
              type = this.labtest_types.find(element => element.key == 'PATIENT')
            }
            const lab_test = {
              name: filename,
              report_id: response.data,
              type_id: type.id,
            }
            const successful = await this.create_or_update_lab_test(lab_test)
            if (successful) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Adjunto cargado',
                  icon: 'SaveIcon',
                  variant: 'success',
                  text: `El adjunto ${filename} fue adjuntado correctamente`,
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error registrando el adjunto',
                  icon: 'ErrorIcon',
                  variant: 'danger',
                  text: `El adjunto ${filename} no ha podido ser cargado al sistema, por favor intente de nuevo`,
                },
              })
            }
          }
          await this.fetchDocuments()
        }).catch(error => {
          console.log(error)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error registrando el adjunto',
              icon: 'ErrorIcon',
              variant: 'danger',
              text: `El adjunto ${filename} no ha podido ser cargado al sistema, por favor intente de nuevo`,
            },
          })
        })
    },
  },
}
</script>

<style>

</style>
