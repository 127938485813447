var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"templateForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('b-card',[_c('b-card-header',[_c('div',{staticClass:"text-primary h2"},[_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"StarIcon","size":"25","variant":"primary"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.$t('process.documents.section.main')))])],1)]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label-for":"document"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('process.documents.initial_exams')))])])],1),_c('b-row'),_c('b-col',{attrs:{"cols":"10"}},[_c('b-form-group',{attrs:{"label-for":"initial_exams"}},[_c('div',[_c('validation-provider',{attrs:{"name":_vm.$t('process.documents.initial_exams')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"initial_exams","accept":".pdf","state":Boolean(_vm.templateData.initial_exams.file),"placeholder":"Adjunte los resultados de laboratorio que le tomaron en el momento de la urgencia","drop-placeholder":"Arrastre el archivo aquí...","browse-text":"Adjuntar"},model:{value:(_vm.templateData.initial_exams.file),callback:function ($$v) {_vm.$set(_vm.templateData.initial_exams, "file", $$v)},expression:"templateData.initial_exams.file"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('b-tooltip',{attrs:{"target":"initial_exams","title":_vm.$t('process.documents.tooltip.initial_exams'),"triggers":"hover","noninteractive":true,"fallback-placement":"counterclockwise"}})],1)])],1),_c('b-col',{attrs:{"cols":"2"}},[(_vm.canDownload(_vm.templateData.initial_exams))?_c('a',{staticClass:"btn btn-primary",attrs:{"href":_vm.getUrl(_vm.templateData.initial_exams),"target":"_blank","role":"button","variant":"primary","aria-pressed":"true"}},[_vm._v(" "+_vm._s(_vm.$t('actions.download'))+" ")]):_vm._e()])],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label-for":"source_exams"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('process.documents.source_exams')))])])],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"10"}},[_c('b-form-group',{attrs:{"label-for":"source_exams"}},[_c('div',[_c('validation-provider',{attrs:{"name":_vm.$t('process.documents.source_exams')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"source_exams","accept":".pdf","state":Boolean(_vm.templateData.source_exams.file),"placeholder":"Si conoce la persona fuente del accidente y dispone de sus exámenes, adjunte aquí el documento","drop-placeholder":"Arrastre el archivo aquí...","browse-text":"Seleccionar"},model:{value:(_vm.templateData.source_exams.file),callback:function ($$v) {_vm.$set(_vm.templateData.source_exams, "file", $$v)},expression:"templateData.source_exams.file"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('b-tooltip',{attrs:{"target":"source_exams","title":_vm.$t('process.documents.tooltip.source_exams'),"triggers":"hover","noninteractive":true,"fallback-placement":"counterclockwise"}})],1)])],1),_c('b-col',{attrs:{"cols":"2"}},[(_vm.canDownload(_vm.templateData.source_exams))?_c('a',{staticClass:"btn btn-primary",attrs:{"href":_vm.getUrl(_vm.templateData.source_exams),"target":"_blank","role":"button","variant":"primary","aria-pressed":"true"}},[_vm._v(" "+_vm._s(_vm.$t('actions.download'))+" ")]):_vm._e()])],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label-for":"patient_picture"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('process.documents.patient_picture')))])])],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"10"}},[_c('b-form-group',{attrs:{"label-for":"patient_picture"}},[_c('div',[_c('validation-provider',{attrs:{"name":_vm.$t('process.documents.patient_picture')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"accept":".png,.jpg,.jpeg","state":Boolean(_vm.templateData.patient_picture.file),"placeholder":"Seleccione el archivo o arrastrelo aquí..","drop-placeholder":"Arrastre el archivo aquí...","browse-text":"Seleccionar"},model:{value:(_vm.templateData.patient_picture.file),callback:function ($$v) {_vm.$set(_vm.templateData.patient_picture, "file", $$v)},expression:"templateData.patient_picture.file"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])],1),_c('b-col',{attrs:{"cols":"2"}},[(_vm.canDownload(_vm.templateData.patient_picture))?_c('a',{staticClass:"btn btn-primary",attrs:{"href":_vm.getUrl(_vm.templateData.patient_picture),"target":"_blank","role":"button","variant":"primary","aria-pressed":"true"}},[_vm._v(" "+_vm._s(_vm.$t('actions.download'))+" ")]):_vm._e()])],1),_c('b-row',[_c('b-col',[_c('router-link',{attrs:{"to":{ name: 'auth-login' }}},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1"},[_vm._v(" "+_vm._s(_vm.$t('actions.cancel'))+" ")])],1),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"submit","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t('actions.send'))+" ")])],1)],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }