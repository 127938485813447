<template>
  <div>
    <validation-observer
      ref="templateForm"
      #default="{ invalid }">
      <b-form @submit.prevent="save">
        <!-- Template Info: Input Fields -->
        <b-card>
          <b-card-header>
            <div class="text-primary h2">
              <feather-icon
                icon="StarIcon"
                size="25"
                class="mr-0 mr-sm-50"
                variant="primary" />
              <span class="d-none d-sm-inline">{{
                $t('process.documents.section.main')
              }}</span>
            </div>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col
                cols="12"
                md="12">
                <b-form-group label-for="document">
                  <span class="font-weight-bold">{{
                    $t('process.documents.initial_exams')
                  }}</span>
                </b-form-group>
              </b-col>
              <b-row />
              <b-col cols="10">
                <b-form-group label-for="initial_exams">
                  <div>
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('process.documents.initial_exams')">
                      <b-form-file
                        id="initial_exams"
                        v-model="templateData.initial_exams.file"
                        accept=".pdf"
                        :state="Boolean(templateData.initial_exams.file)"
                        placeholder="Adjunte los resultados de laboratorio que le tomaron en el momento de la urgencia"
                        drop-placeholder="Arrastre el archivo aquí..."
                        browse-text="Adjuntar" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <!-- ? INIT_EXAMS -->
                    <b-tooltip
                      target="initial_exams"
                      :title="$t('process.documents.tooltip.initial_exams')"
                      triggers="hover"
                      :noninteractive="true"
                      fallback-placement="counterclockwise">
                    </b-tooltip>
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <a
                  v-if="canDownload(templateData.initial_exams)"
                  :href="getUrl(templateData.initial_exams)"
                  class="btn btn-primary"
                  target="_blank"
                  role="button"
                  variant="primary"
                  aria-pressed="true">
                  {{ $t('actions.download') }}
                </a>
              </b-col>
            </b-row>
            <hr />
            <b-row>
              <b-col
                cols="12"
                md="12">
                <b-form-group label-for="source_exams">
                  <span class="font-weight-bold">{{
                    $t('process.documents.source_exams')
                  }}</span>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="10">
                <b-form-group label-for="source_exams">
                  <div>
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('process.documents.source_exams')">
                      <b-form-file
                        id="source_exams"
                        v-model="templateData.source_exams.file"
                        accept=".pdf"
                        :state="Boolean(templateData.source_exams.file)"
                        placeholder="Si conoce la persona fuente del accidente y dispone de sus exámenes, adjunte aquí el documento"
                        drop-placeholder="Arrastre el archivo aquí..."
                        browse-text="Seleccionar" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <!-- ? EXAMS -->
                    <b-tooltip
                      target="source_exams"
                      :title="$t('process.documents.tooltip.source_exams')"
                      triggers="hover"
                      :noninteractive="true"
                      fallback-placement="counterclockwise">
                    </b-tooltip>
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <a
                  v-if="canDownload(templateData.source_exams)"
                  :href="getUrl(templateData.source_exams)"
                  class="btn btn-primary"
                  target="_blank"
                  role="button"
                  variant="primary"
                  aria-pressed="true">
                  {{ $t('actions.download') }}
                </a>
              </b-col>
            </b-row>
            <hr />
            <b-row>
              <b-col
                cols="12"
                md="12">
                <b-form-group label-for="patient_picture">
                  <span class="font-weight-bold">{{
                    $t('process.documents.patient_picture')
                  }}</span>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="10">
                <b-form-group label-for="patient_picture">
                  <div>
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('process.documents.patient_picture')">
                      <b-form-file
                        v-model="templateData.patient_picture.file"
                        accept=".png,.jpg,.jpeg"
                        :state="Boolean(templateData.patient_picture.file)"
                        placeholder="Seleccione el archivo o arrastrelo aquí.."
                        drop-placeholder="Arrastre el archivo aquí..."
                        browse-text="Seleccionar" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <a
                  v-if="canDownload(templateData.patient_picture)"
                  :href="getUrl(templateData.patient_picture)"
                  class="btn btn-primary"
                  target="_blank"
                  role="button"
                  variant="primary"
                  aria-pressed="true">
                  {{ $t('actions.download') }}
                </a>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <router-link :to="{ name: 'auth-login' }">
                  <b-button class="mb-1 mb-sm-0 mr-0 mr-sm-1">
                    {{ $t('actions.cancel') }}
                  </b-button>
                </router-link>
                <b-button
                  variant="primary"
                  type="submit"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :disabled="invalid">
                  {{ $t('actions.send') }}
                </b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BCard,
  BCardHeader,
  BCardBody,
  BFormFile,
  BTooltip,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BCard,
    BCardHeader,
    ValidationProvider,
    ValidationObserver,
    BCardBody,
    BFormFile,
    BTooltip,
  },
  props: {
    templateData: {
      type: Object,
      required: true,
    },
    newTemplate: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      // validation
      required,
      resourcesElementName: 'first_date_documents',
    }
  },
  methods: {
    canDownload(item) {
      return !!item.name
    },
    getName(item) {
      return `ACTUAL: ${item && item.name ? item.name : ''}`
    },
    getUrl(item) {
      if (!item || !item.path) {
        return ''
      }
      let path = item.path.split('/')
      path = path[path.length - 1]
      return `${process.env.VUE_APP_BASE_API}/api/v1/digital_file/digital_file/record/${path}`
    },
    save() {
      this.$refs.templateForm.validate().then(async (success) => {
        if (success) {
          if (
            this.templateData.initial_exams.file.size &&
            this.templateData.initial_exams.file.size > 0
          ) {
            const InstFormData = new FormData()
            InstFormData.append('file', this.templateData.initial_exams.file)
            await this.$parent.save(InstFormData, 'INITIAL', 'initial_exams')
          }
          if (
            this.templateData.source_exams.file &&
            this.templateData.source_exams.file.size > 0
          ) {
            const InstFormData = new FormData()
            InstFormData.append('file', this.templateData.source_exams.file)
            await this.$parent.save(InstFormData, 'INITIAL', 'source_exams')
          }
          if (
            this.templateData.patient_picture.file.size &&
            this.templateData.patient_picture.file.size > 0
          ) {
            const InstFormData = new FormData()
            InstFormData.append('file', this.templateData.patient_picture.file)
            await this.$parent.save(InstFormData, 'INITIAL', 'patient_picture')
          }
        }
      })
    },
  },
}
</script>

<style></style>
